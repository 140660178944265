import EvaluationStates from '../States/EvaluationStates'
import better_img from '../../assets/img/positivo.png'
import neutral_img from '../../assets/img/neutro.png'
import recoil_img from '../../assets/img/negativo.png'
import withoutChanges_img from '../../assets/img/sin-valores.png'

interface IStatusEvaluation {
    evaluation_right: number | null;
    stateStyleText: string;
    evaluationStateStyleText: string;
}

const better = 1;
const neutral = 0;
const recoil = -1;
const withoutChanges = null

const HandleGraph = ({ evaluation_right }: { evaluation_right: number | null }) => {
    switch (evaluation_right) {
        case better:
            return (<img src={better_img} alt='Evalución del derecho' />)
            break;
        case neutral:
            return (<img src={neutral_img} alt='Evalución del derecho' />)
        case recoil:
            return (<img src={recoil_img} alt='Evalución del derecho' />)
        case withoutChanges:
            return (<img src={withoutChanges_img} alt='Evalución del derecho' />)
        default:
            return null
            break;
    }
}


const StatusEvaluation = ({ evaluation_right, stateStyleText, evaluationStateStyleText }: IStatusEvaluation) => {
    return (
        <div className='rounded-xl bg-primary-100 w-full'>
            <div className='p-4  w-full flex justify-between items-center'>
                <div className="flex flex-col w-full items-start">
                    <p className={`${stateStyleText} text-primary-10`}>Estado</p>
                    <EvaluationStates status={evaluation_right} evaluationStateStyleText={evaluationStateStyleText} />
                </div>
                <div className="w-full h-full flex justify-end">
                    <HandleGraph evaluation_right={evaluation_right} />
                </div>
            </div>
        </div>
    )
}

export default StatusEvaluation