import Hero from "../../components/Hero/Hero"
import QuickAccessToRights from "./components/QuickAccessToRights"
import QuickAccessToResearch from "./components/QuickAccessToResearch"
import FeaturedActivities from "./components/FeaturedActivities"
import DoubtsOrQueries from "../../components/Cards/DoubtsOrQueries"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { useEffect } from "react"
import { getActivities } from "../../redux/slices/activities/activitiesThunk"
import { getVideo } from "../../redux/slices/monitor/monitorThunk"
import { getRights } from "../../redux/slices/rights/rightsThunk"

const Index = () => {
    const dispatch = useAppDispatch();
    const activities = useAppSelector(state => state?.activities.majorActivities);
    const video = useAppSelector((state) => state.monitor.video);
    const { rights, evaluation_periodicity } = useAppSelector((state) => state.rights);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!video && !rights) {
                    await Promise.all([
                        dispatch(getVideo()),
                        dispatch(getRights())
                    ]);
                }
                dispatch(getActivities({ page: 1 }));
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
            }
        };
        fetchData();
    }, [video, rights, dispatch]);

    return (
        <>
            <Hero
                breadcrumbs={<Breadcrumbs />}
                title={"Monitor de Derechos"}
                paragraph="¡Te damos la bienvenida al Monitor de Derechos de la Defensoría del Pueblo de la Ciudad de Buenos Aires! Esta plataforma evalúa la situación de diversos derechos en la ciudad, para fortalecer su protección. A través del Semáforo, podrás identificar mejoras o retrocesos en áreas como educación, salud, vivienda y trabajo. El Visualizador de Indicadores te ofrece datos gráficos y detallados para explorar tendencias desde una perspectiva inclusiva. En el espacio de Investigaciones y Acciones, encontrás estudios del Monitor que abordan estos temas, brindando una visión integral de la realidad. ¿Querés saber más? ¡Mirá nuestro video explicativo!"
                media={{ type: 'video', src: '', url: `${video?.link}`, alt: `${video?.titulo}` }}
                withShape={true}
                layout="row"
                containerClassName='xl:items-end'
            />
            <div className="pt-16 xl:pt-32 "></div>
            {
                rights && rights.length > 0
                    ? <QuickAccessToRights
                        quickAccessToRights={rights ? rights : []}
                        dateOfEvaluation={evaluation_periodicity}
                    />
                    : <></>
            }
            {/* <QuickAccessToResearch /> */}
            {
                activities && activities?.length > 0
                    ? <FeaturedActivities activities={activities} />
                    : <></>
            }
            <DoubtsOrQueries />
        </>
    )
}

export default Index 