import React from 'react'
import { MdArrowForward } from "react-icons/md";
import Button from '../Buttons/Button';
import { Link } from 'react-router-dom';
import ResponsiveContainer from '../../layouts/ResponsiveContainer';

const DoubtsOrQueries = () => {
    return (
        <div className="flex flex-col justify-center w-full items-center mt-16">
            <ResponsiveContainer className='bg-[#D7D9E5]/30 rounded-lg'>
                <div className='flex flex-col gap-2 py-6 px-8'>
                    <h4 className='text-tlarge text-primary-10'>¿Tenés dudas o consultas?</h4>
                    <Link to='/monitor/contactanos' >
                        <Button label='Contactanos' icon={MdArrowForward} as='link' className='text-primary-40' />
                    </Link>
                </div>
            </ResponsiveContainer>
        </div>
    )
}

export default DoubtsOrQueries