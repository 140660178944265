import Hero from '../../components/Hero/Hero'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import whatIs from '../../assets/img/que-es.png'
import DoubtsOrQueries from '../../components/Cards/DoubtsOrQueries'
import GoalsAndObjectives from './components.tsx/GoalsAndObjectives'
import WorkTeam from './components.tsx/WorkTeam'
import FAQS from './components.tsx/FAQS'

const index = () => {
    return (
        <div>
            <Hero
                breadcrumbs={<Breadcrumbs />}
                title='¿Qué es el monitor?'
                paragraph={`El Monitor de Derechos cuenta con un equipo de trabajo interdisciplinario que, con un enfoque colaborativo, tiene a su cargo la recopilación y el análisis de datos que, en su mayoría, son oficiales.
                En concreto, releva, verifica y organiza información de diversas fuentes de carácter local y nacional. Luego, esa información se traslada al visualizador de indicadores y - a través del uso de una metodología adecuada y herramientas tecnológicas - se procede a la evaluación de cada indicador, subdimensión, dimensión, tipo de indicador y derecho. Garantizando la transparencia del proceso, es posible identificar avances y retrocesos en el cumplimiento del derecho, señalar mejoras, identificar vulneraciones sistemáticas y proponer transformaciones que contribuyan a superarlas. `}
                media={{ type: 'image', url: '', src: whatIs, alt: 'que-es-el-monitor' }}
                withShape={true}
                layout='column'
            />
            <GoalsAndObjectives />
            <FAQS />
            <WorkTeam />
            <div className='flex flex-col items-center'>
                <div className='flex flex-col justify-center w-[70.5rem] lg:max-xl:w-[55rem]'>
                    <DoubtsOrQueries />
                </div>
            </div>
        </div>
    )
}

export default index