import QuickAccess from '../Cards/AccessToRigths'
import QuickAccessButton from '../Cards/QuickAccessButton'
import { IRights } from '../../interfaces/Rights'
import ResponsiveContainer from '../../layouts/ResponsiveContainer'

const AccessToRigthsMapper = ({ quickAccessToRights }: { quickAccessToRights: IRights[] }) => {

    return (
        <ResponsiveContainer className="flex flex-wrap gap-6 pt-6">
            {
                quickAccessToRights.map((right) => (
                    <div key={right.id} className="w-full md:w-[26rem] lg:w-[26.75rem] xl:w-[34.5rem]">
                        {/* Card */}
                        <QuickAccess
                            id={right.id}
                            color={right.color}
                            icono={right.icono}
                            icono_base64={right.icono_base64}
                            titulo={right.titulo}
                            estado={right.estado}
                            tipo_indicadores={right.tipo_indicadores}
                            resultado_nominal={right.resultado_nominal}
                            resultado_trivalente={right.resultado_trivalente}
                        />
                        {/* Buttons */}
                        <div className="flex flex-col gap-2 pt-2 ">
                            <QuickAccessButton
                                label='Evaluador'
                                // Modifica por qa
                                disabled={true}
                            />
                            <QuickAccessButton
                                label='Visualizador de indicadores'
                                // Modifica por qa
                                disabled={true}
                            />
                        </div>
                    </div>
                ))
            }
        </ResponsiveContainer>
    )
}

export default AccessToRigthsMapper