import React from 'react'
import StackedBarChart from '../Charts/StackedBarChart'
import { IIndicatorData } from '../../interfaces/Rights';

const Indicators = ({ data, showIndicators }: { data: IIndicatorData[], showIndicators: boolean }) => {
    return (
        <div className='rounded-xl bg-primary-100 w-full'>
            <div className='p-4 w-full'>
                {showIndicators ? <h6 className='text-llarge text-primary-10 text-center'>Indicadores por estado</h6> : <></>}
                <StackedBarChart data={data} showIndicators={showIndicators} />
            </div>
        </div>
    )
}

export default Indicators