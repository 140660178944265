import { useState } from 'react'
import { BiSolidChevronDown } from "react-icons/bi";
import { ICategories } from '../../../interfaces/Rights';

export interface ISelectCategories {
    categories: ICategories[],
    onCategorySelect: (selectedCategory: string) => void;
    categorieSelect: string,
    defaultCategorieValue: string
}

const Categories = ({ categories, onCategorySelect, categorieSelect, defaultCategorieValue }: ISelectCategories) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <div className='relative min-w-[183px]'>
            <div className='flex items-center justify-between gap-1 md:gap-4 px-2 md:px-4 py-1 border rounded-md border-system-light-50'>
                <span className='text-llarge'>Categoría: {categorieSelect.length > 0 ? categorieSelect : defaultCategorieValue}</span>
                <button className='bg-transparent' onClick={() => setOpen(!open)}>
                    <BiSolidChevronDown />
                </button>
            </div>
            {
                open ?
                    <div className='absolute w-full rounded-lg shadow shadow-gray-0/30 bg-primary-100 z-40'>
                        <div className='flex flex-col'>
                            {
                                categories && categories.length && categories.map((categorie) => (
                                    <span
                                        className={`text-llarge py-2 px-3 hover:bg-gray-95 cursor-pointer ${categorieSelect.includes(categorie.titulo) ? 'bg-[#DEE3E6]' : ''}`}
                                        key={categorie.id}
                                        onClick={() => { onCategorySelect(categorie.titulo); setOpen(false) }}
                                    >
                                        {categorie.titulo}
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default Categories
