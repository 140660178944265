import React, { useState } from 'react'
import copy from '../../Pages/Profile/img/copy-link.svg'

type Props = {
    copyText: string
}

const ClipboardCopy = ({ copyText }: Props) => {

    const [isCopied, setIsCopied] = useState<boolean>(false)

    async function copyTextToClipboard(text: string) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(`${copyText}`);
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(copyText)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className='w-full border-2 rounded-xl bg-[#F9F9F9] border-[#E0E0E0]'>
            <div className='p-2 flex justify-between text-[13.78px]'>
                <input type='text' value={copyText} readOnly className='bg-[#F9F9F9] truncate ' />
                {
                    !isCopied
                        ?
                        <button onClick={handleCopyClick} className='bg-primary-40 text-primary-100 px-3 py-1 rounded-xl'>
                            Copiar
                        </button>
                        : <span className='text-primary-40 font-bold px-3 py-1 '>¡Copiado!</span>
                }
            </div>
        </div>
    )
}

export default ClipboardCopy