import React from 'react'
import '../../styles.css'

interface IPeriod {
    labelPeriod: string,
    onHandleChangePeriod: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabledPeriod: boolean;
    minPeriodDate: string;
    maxPeriodDate: string;
    value: string
}

const Period = ({ labelPeriod, onHandleChangePeriod, disabledPeriod, minPeriodDate, maxPeriodDate, value }: IPeriod) => {
    return (
        <div className={`${disabledPeriod ? 'opacity-50' : ''} flex flex-col text-bsmall`}>
            <label>{labelPeriod}</label>
            <div className='px-2 md:px-4 md:py-1 py-[3px] border rounded-md border-system-light-50 text-llarge'>
                <input
                    type='date'
                    className="period-input w-full"
                    placeholder="DD/MM/YYYY"
                    onChange={onHandleChangePeriod}
                    disabled={disabledPeriod}
                    min={minPeriodDate}
                    max={maxPeriodDate}
                    value={value}
                />
            </div>
        </div>
    )
}

export default Period
