
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import DetailOfMedia from './components/DetailOfMedia';
import DoubtsOrQueries from '../../components/Cards/DoubtsOrQueries';
import RelatedContent from './components/RelatedContent';
import { IMediaAndContent } from '../../interfaces/MediaAndContent';
import ResponsiveContainer from '../../layouts/ResponsiveContainer';

interface ContentDetail {
    content: IMediaAndContent,
    relatedContent: IMediaAndContent[],
    titleRelatedContent: string
}

const Index = ({ content, relatedContent, titleRelatedContent }: ContentDetail) => {


    return (
        <div className='mt-16 flex justify-center'>
            <ResponsiveContainer>
                <Breadcrumbs />
                <DetailOfMedia
                    id={content?.id}
                    descripcion={content?.descripcion}
                    titulo={content?.titulo}
                    categorias={content?.categorias}
                    imagen={content.imagen}
                    imagen_base64={content.imagen_base64}
                    fecha={content.fecha}
                    texto={content.texto}
                    pdf={content.pdf}
                    pdf_base64={content.pdf_base64}
                    url={content.url}
                />
                {relatedContent && relatedContent.length > 0
                    ? <div className='mt-12'>
                        <RelatedContent title={titleRelatedContent} relatedContent={relatedContent ?? []} />
                    </div>
                    : <></>
                }
                <DoubtsOrQueries />
            </ResponsiveContainer>
        </div>
    )
}

export default Index

/*
Este componente es reutilizado en actividades e investigaciones y acciones
*/