import x from '../../assets/icons/x.png';
import facebook from '../../assets/icons/facebook.png';
import email from '../../assets/icons/email.png';
import whatsapp from '../../assets/icons/whatsapp.png';
import linkedin from '../../assets/icons/linkedn.png';
import telegram from '../../assets/icons/telegram.png';
import reddit from '../../assets/icons/reddit.png';
import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useState } from 'react';

interface IShare {
    newsUrl: string,
    newsTitle: string,
    description: string
}

const FUENTE = 'Fuente: Defensoría del Pueblo de CABA';

const encodeText = (text: string) => encodeURIComponent(text);

const createHref = (baseUrl: string, params: Record<string, string>) => {
    const queryString = Object.keys(params)
        .map(key => `${key}=${encodeText(params[key])}`)
        .join('&');
    return `${baseUrl}?${queryString}`;
};

const Share = ({ newsUrl, newsTitle, description }: IShare) => {
    const socials = [
        {
            outlet: "WhatsApp",
            href: createHref('https://api.whatsapp.com/send', { text: `*${newsTitle}*\n${description}\n${newsUrl}\n${FUENTE}` }),
            label: 'WhatsApp',
            icon: whatsapp
        },
        {
            outlet: "Email",
            href: `mailto:?subject=${encodeText(newsTitle)}&body=${encodeText(`${description}\n${FUENTE}`)}`,
            background: "#dd4b39",
            color: "white",
            label: "Email",
            icon: email
        },
        {
            outlet: "Facebook",
            href: createHref('https://www.facebook.com/sharer.php', { u: newsUrl, s: newsTitle, p: `${description}\n${FUENTE}` }),
            label: 'Facebook',
            icon: facebook
        },
        {
            outlet: "X",
            href: createHref('https://twitter.com/intent/tweet', { text: `${newsTitle}\n${description}\n${newsUrl}` }),
            label: 'X',
            icon: x
        },
        {
            outlet: "LinkedIn",
            href: createHref('https://www.linkedin.com/shareArticle', { mini: 'true', url: newsUrl, title: newsTitle, summary: description, source: FUENTE }),
            label: 'LinkedIn',
            icon: linkedin
        },
        {
            outlet: 'Telegram',
            href: createHref('https://t.me/share/url', { url: newsUrl, text: `${newsTitle}\n${description}\n${FUENTE}` }),
            label: 'Telegram',
            icon: telegram
        },
        {
            outlet: 'Reddit',
            href: createHref('https://www.reddit.com/submit', { url: newsUrl }),
            label: 'Reddit',
            icon: reddit
        }
    ];

    const [visibleCount, setVisibleCount] = useState(5);

    const handleShowMore = () => {
        setVisibleCount(socials.length);
    };

    const handleShowLess = () => {
        setVisibleCount(5);
    };

    return (
        <>
            <p className="font-bold text-[#0F0F0F]">Compartir</p>
            <div className="my-3">
                <div className='flex flex-row flex-wrap gap-2'>
                    {
                        socials && socials.slice(0, visibleCount).map((social) => (
                            <a
                                data-action={social.label === 'WhatsApp' ? "share/whatsapp/share" : ''}
                                href={`${social.href}`}
                                target="_blank"
                                className="flex flex-col items-center gap-2 w-[3.75rem]"
                                rel="noreferrer"
                                key={social.label}
                            >
                                <img src={social.icon} alt={social.label} className='w-[3.75rem] h-[3.75rem]' />
                                <span className='font-normal text-[11.49px]'>{social.outlet}</span>
                            </a>
                        ))
                    }
                </div>
                {visibleCount < socials.length && (
                    <button
                        onClick={handleShowMore}
                        className="mt-3 text-primary-40 font-medium flex items-center gap-1 pb-3"
                    >
                        Más redes
                        <FiChevronDown />
                    </button>
                )}
                {
                    visibleCount === socials.length && (
                        <button
                            onClick={handleShowLess}
                            className="mt-3 text-primary-40 font-medium flex items-center gap-1 pb-3"
                        >
                            Ocultar redes <FiChevronUp />
                        </button>
                    )
                }
            </div>
            <ClipboardCopy copyText={newsUrl} />
        </>
    );
}

export default Share;