import Loading from "../../../components/Loading/Loading";
import AccessToRigthsMapper from "../../../components/Mappers/AccessToRigthsMapper"
import Titles from "../../../components/Titles/Titles"
import { IRights } from "../../../interfaces/Rights"
import ResponsiveContainer from "../../../layouts/ResponsiveContainer"

interface IQuickAccessToRights {
    quickAccessToRights: IRights[],
    dateOfEvaluation: string,
}

const QuickAccessToRights = ({ quickAccessToRights, dateOfEvaluation }: IQuickAccessToRights) => {
    return (
        <div className="flex flex-col justify-center w-full items-center">
            {/* Encabezado */}
            <ResponsiveContainer className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
                <Titles title={"Derechos"} />
                <span className="text-blarge text-primary-10">{dateOfEvaluation}</span>
            </ResponsiveContainer>
            <AccessToRigthsMapper quickAccessToRights={quickAccessToRights} />
        </div>
    )
}

export default QuickAccessToRights