import Root from "./routes/Root";
import "./FirebaseConfig";

function App() {
  return (
    <Root />
  );
}

export default App;
