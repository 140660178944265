function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
        return r + "," + g + "," + b;
    }
    return undefined;
}

function titleCase(string: string) {
    let stringCapitalize = string[0].toLocaleUpperCase() + string.slice(1).toLowerCase()
    return stringCapitalize
}

function getImageFormat(filename: string): string | null {
    const regex = /(?:\.([^.]+))?$/;
    const match = regex.exec(filename);

    if (match && match[1]) {
        const format = match[1].toLowerCase();
        const validFormats = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg'];

        if (validFormats.includes(format)) {
            return format;
        } else {
            return 'Invalid format';
        }
    }

    return null;
}

const getValueID = (string: string | undefined) => {
    if (string !== undefined) {
        const expresionRegular = /id(\d+)/;
        const value_ID = string.match(expresionRegular);

        if (value_ID) {
            return value_ID[1]

        } else {
            return null
        }
    } else {
        return
    }
}

export { hexToRgb, titleCase, getImageFormat, getValueID }