import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Hero from '../../components/Hero/Hero'
import SearchBar from '../../components/SearchBar/SearchBar'
import Filters from './components/Filters'
import DoubtsOrQueries from '../../components/Cards/DoubtsOrQueries'
import MediaAndContentMapper from '../../components/Mappers/MediaAndContentMapper'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { getActivities, getCategories } from '../../redux/slices/activities/activitiesThunk'
import Loading from '../../components/Loading/Loading'
import Pagination from '../../components/Pagination/Pagination'
import { useLocation, useNavigate } from 'react-router-dom'
import ResponsiveContainer from '../../layouts/ResponsiveContainer'

const DEFAULT_MIN_DATE = '2024-01-01';

const Index = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Obtención de datos del estado global de Redux
    const { activities, pagination, loading, categories } = useAppSelector((state) => state.activities)
    const { currentPage, totalPages, totalItems } = pagination;

    // Estados locales para filtros y fechas
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [categorieSelected, setCategorieSelected] = useState<string>('');
    const [dateFrom, setDateFrom] = useState<string>('');
    const [dateTo, setDateTo] = useState<string>('');
    const [disabledPeriodFrom, _setDisabledPeriodFrom] = useState<boolean>(false);
    const [disabledPeriodTo, setDisabledPeriodTo] = useState<boolean>(true);
    const [minPeriodDateFrom, _setMinPeriodDateFrom] = useState<string>(DEFAULT_MIN_DATE);
    const [minPeriodDateTo, setMinPeriodDateTo] = useState<string>('');
    const [maxPeriodDateFrom, setMaxPeriodDateFrom] = useState<string>('');
    const [maxPeriodDateTo, setMaxPeriodDateTo] = useState<string>('');

    // Efecto para cargar categorías y establecer límite de fecha máximo
    useEffect(() => {
        if (!categories?.length) {
            dispatch(getCategories())
        }
        maxDateLimit();
    }, [categories?.length])

    // Efecto para manejar cambios en la URL y actualizar filtros
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('search') || '';
        const page = parseInt(params.get('page') || '1', 10);
        const categorie = params.get('categorie') || '';
        const dateTo = params.get('to') || "";
        const dateFrom = params.get('from') || "";

        // Actualización de los estados con los parámetros de la URL
        if (categorie) setCategorieSelected(categorie);
        if (dateFrom) setDateFrom(dateFrom);
        if (dateTo) { setDateTo(dateTo); setDisabledPeriodTo(false) };
        if (query) setSearchQuery(query);

        // Despacho de la acción para obtener actividades basadas en los parámetros de la URL
        dispatch(getActivities({ categoria: categorie, page, search: query, fechaTo: dateTo, fechaFrom: dateFrom }));
    }, [dispatch, location.search]);

    // Manejo de cambio de página en la paginación
    const handlePageChange = (page: number) => {
        const params = new URLSearchParams(location.search);
        searchQuery && params.set('search', searchQuery);
        params.set('page', page.toString());
        navigate({ search: params.toString() });
    };

    // Manejo de búsqueda
    const handleSearch = (query: string) => {
        const params = new URLSearchParams(location.search);
        setSearchQuery(query);
        query ? params.set('search', query) : params.delete('search');
        params.set('page', '1');
        navigate({ search: params.toString() });
    };

    // Manejo de selección de categoría
    const onCategorySelect = (value: string) => {
        const params = new URLSearchParams(location.search);
        setCategorieSelected(value !== 'Todas' ? value : '');
        value !== 'Todas' ? params.set('categorie', value) : params.delete('categorie');
        navigate({ search: params.toString() });
    };

    // Manejo de cambio en la fecha "Desde"
    const onHandleChangePeriodFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value;
        const params = new URLSearchParams(location.search);
        if (date === "") {
            setDisabledPeriodTo(true);
            setDateTo('');
            setDateFrom('');
            params.delete('from');
            params.delete('to');
        } else {
            setDateFrom(date);
            setDateTo('');
            setDisabledPeriodTo(false);
            setMinPeriodDateTo(e.target.value)
        }
        navigate({ search: params.toString() });
    }

    // Manejo de cambio en la fecha "Hasta"
    const onHandleChangePeriodTo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateTo = e.target.value;
        const params = new URLSearchParams(location.search);
        if (dateTo !== '') {
            setDateTo(e.target.value);
            params.set('from', dateFrom);
            params.set('to', dateTo);
            params.set('page', '1');
        } else {
            setDateTo('');
            params.delete('to');
        }
        navigate({ search: params.toString() });
    }

    // Función para establecer el límite máximo de la fecha al día actual
    const maxDateLimit = () => {
        const currentDay = new Date().toISOString().slice(0, 10);
        setMaxPeriodDateFrom(currentDay);
        setMaxPeriodDateTo(currentDay);
    };

    //Arma objetos para pasar props al componente Filter
    const dateProps = {
        onHandleChangePeriodFrom,
        onHandleChangePeriodTo,
        disabledPeriodFrom,
        disabledPeriodTo,
        minPeriodDateFrom,
        maxPeriodDateFrom,
        minPeriodDateTo,
        maxPeriodDateTo,
        valueDateFrom: dateFrom,
        valueDateTo: dateTo,
    };

    const categoryProps = {
        categories: categories ? categories : [],
        numberOfArticles: totalItems,
        onCategorySelect,
        categorieSelect: categorieSelected,
        defaultCategorieValue: 'Todas',
    };

    return (
        <>
            <Hero
                breadcrumbs={<Breadcrumbs />}
                title='Actividades destacadas'
                paragraph='Enterate acá de las actividades más importantes que llevamos adelante para promover los derechos humanos en la Ciudad de Buenos Aires: espacios de trabajo conjunto con otras áreas de la Defensoría del Pueblo, encuentros con organizaciones sociales, reuniones con organismos, jornadas de estudio, eventos de difusión, inicio de trámites y pedidos de informes a las autoridades, entre muchas otras.'
                media={{ type: '', src: '', url: '', alt: '' }}
                withShape={false}
                layout='row'
            />
            <div className='flex flex-col items-center'>
                <ResponsiveContainer className='flex flex-col justify-center' >
                    <div className='mt-16'>
                        <SearchBar
                            onSearch={handleSearch}
                            defaultValue={searchQuery}
                        />
                        <Filters
                            dateProps={dateProps}
                            categoryProps={categoryProps}
                        />
                    </div>
                    {loading === 'pending' ? (
                        <Loading />
                    ) : (
                        <>
                            {activities?.length === 0 ? (
                                <div className="text-center mt-8">No se encontraron actividades.</div>
                            ) : (
                                <>
                                    <MediaAndContentMapper data={activities} viewType='large' />
                                    <Pagination
                                        data={activities || []}
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        setCurrentPage={handlePageChange}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <DoubtsOrQueries />
                </ResponsiveContainer>
            </div>
        </>
    )
}

export default Index