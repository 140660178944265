import { useState } from 'react'
import { IRights } from '../../interfaces/Rights'
import Images from '../Images/Images'
import '../styles.css'
import Indicators from './Indicators'
import StatusEvaluation from "./StatusEvaluation"
import Dialog from '../Dialog/Dialog'
import DialogContent from '../Dialog/DialogContent'
import { MdBuildCircle } from 'react-icons/md'

// --- 
// En esta fase todavia no llegan las evaluaciones de los derechos por lo que se pasa simplemente null.
// --- 
const AccessToRigths = ({ color, icono, icono_base64, titulo, tipo_indicadores }: IRights) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <button onClick={() => setIsOpen(!isOpen)} className="relative rounded-xl h-fit w-full">
                {/* <div > */}
                <div className='absolute w-full h-full rounded-xl w-full' style={{ backgroundColor: color }}>
                    <div className="imagen-bg-card-rights rounded-xl"></div>
                </div>
                <div className='w-full p-4 relative z-10'>
                    <div className='flex items-center gap-4 pb-4'>
                        <Images imagen={icono} imagen_base64={icono_base64} className="w-[4.25rem] h-[3.375rem] object-contain" />
                        <h2 className='text-hmedium xl:text-hlarge text-primary-100'>{titulo}</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row gap-3 w-full'>
                        <Indicators data={tipo_indicadores} showIndicators={true} />
                        <StatusEvaluation evaluation_right={null} stateStyleText="text-llarge" evaluationStateStyleText="text-llarge" />
                    </div>
                </div>
                {/* </div> */}
            </button>
            <Dialog
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
            >
                <>
                    <div className="flex justify-center mb-2">
                        <MdBuildCircle size={32} color='#0E6681' />
                    </div>
                    <h2 className="text-center text-hsmall text-system-light-100 mb-4">¡En construcción!</h2>
                    <p className="text-bmedium text-system-light-100 mb-3">
                        Estamos construyendo de modo colaborativo los indicadores para el evaluador y el visualizador. Te invitamos a formar parte en
                        <a className='text-primary-40 ps-2' rel="noreferrer" href='mailto:monitordederechos@defensoria.org.ar'> monitordederechos@defensoria.org.ar</a>
                    </p>
                </>
            </Dialog>
        </>
    )
}

export default AccessToRigths
