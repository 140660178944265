import { createSlice } from '@reduxjs/toolkit'
import { getRights } from './rightsThunk';
import { IRights } from '../../../interfaces/Rights';

interface RightsState {
    rights: IRights[] | null;
    loading: "idle" | "pending" | "succeeded" | "failed";
    evaluation_periodicity: string
}

const initialState: RightsState = {
    loading: 'idle',
    rights: null,
    evaluation_periodicity: ''
}

export const rightsSlice = createSlice({
    name: 'rights',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRights.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getRights.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.rights = action.payload.rights;
            state.evaluation_periodicity = action.payload.evaluation_periodicity;
        });
        builder.addCase(getRights.rejected, (state) => {
            state.loading = "failed";
        });

    },
})

export const { } = rightsSlice.actions;

export default rightsSlice.reducer;