import { NavLink } from 'react-router-dom';
import './styles.css'

const NavBar = () => {
    const links = [
        { id: 1, name: 'Home', address: "https://defensoria.org.ar/" },
        { id: 2, name: 'Monitor', address: "/monitor" },
        // { id: 3, name: 'Investigaciones y acciones', address: "/monitor/investigaciones-y-acciones" },
        { id: 4, name: '¿Qué es?', address: "/monitor/que-es" },
        { id: 5, name: 'Actividades', address: "/monitor/actividades" }
    ]

    return (
        <nav className='flex flex-col md:flex-row md:items-center md:gap-4 md:h-[2.5rem] bg-primary-100'>
            {
                links.map(({ id, name, address }) => (
                    <NavLink
                        end
                        key={id}
                        to={address}
                        className={({ isActive }) => isActive ? "active" : ""}
                    >
                        {name}
                    </NavLink>
                ))
            }
        </nav>
    )
}

export default NavBar